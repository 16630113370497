<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <div
        v-if="$route.params.userId"
        class="head-card"
      >
        <div class="head-card-top">
          <h4>{{ $t('Campaigns') }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div
        v-else-if="rows.length !== 0"
        class="campaigns-table"
      >
        <div class="custom-search">
          <div class="total">
            <strong>{{ $t('campaigns.campaigntotal') }}: {{ totals }}</strong>
          </div>
          <div class="table-filters">
            <div class="d-flex align-items-center">
              <b-input-group
                class="input-group-merge pnx-search"
              >
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="cursor-pointer"
                    @click="searchTerm = $refs.searchInput.vModelValue"
                  />
                </b-input-group-prepend>
                <b-form-input
                  :value="searchTerm"
                  :placeholder="$t('form.search')"
                  type="text"
                  class="d-inline-block"
                  @keyup.enter="searchTerm = $event.target.value"
                />
                <b-input-group-append is-text>
                  <div class="search-input-clear">
                    <feather-icon
                      v-if="searchTerm!==''"
                      v-b-tooltip.hover.top="$t('form.reset')"
                      class="cursor-pointer"
                      size="18"
                      icon="XIcon"
                      @click="clearSearch"
                    />
                  </div>
                  <b-dropdown
                    ref="dropdownFilter"
                    :variant="dropdownFilterVariant"
                    toggle-class="text-decoration-none"
                    right
                    no-caret
                    offset="15"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        size="18"
                        icon="SlidersIcon"
                      />
                    </template>
                    <b-dropdown-form
                      :style="{minWidth: '292px'}"
                    >
                      <h5>
                        {{ $t('campaigns.filters') }}
                      </h5>
                      <hr>
                      <div class="d-flex align-items-center mb-1">
                        <label
                          class="mr-1"
                          :style="{minWidth: '60px'}"
                        >{{ $t('campaigns.status') }}</label>
                        <b-form-select
                          :value="filterStatus"
                          :options="[{ value: '', text: this.$t('users.all') }, ...optionsStatuses]"
                          @change="(value) => filterStatus = value"
                        />
                      </div>

                      <div class="d-flex align-items-center mb-1">
                        <label
                          class="mr-1"
                          :style="{minWidth: '60px'}"
                        >{{ $t('campaigns.type') }}</label>
                        <b-form-select
                          :value="getFilterValue('type')"
                          :options="[{ value: '', text: this.$t('users.all') }, ...campaignType]"
                          @change="(value) => filterFn('type', value)"
                        />
                      </div>
                      <div class="d-flex align-items-center  mb-1">
                        <label
                          class="mr-1"
                          :style="{minWidth: '60px'}"
                        >{{ $t('stats.country') }}</label>
                        <b-form-select
                          :value="getFilterValue('country_name')"
                          :options="[{ value: '', text: this.$t('users.all') }, ...countries]"
                          @change="(value) => filterFn('country_name', value)"
                        />
                      </div>

                      <b-button
                        v-if="filterStatus!=='' || getFilterValue('type') !=='' || getFilterValue('country_name') !==''"
                        block
                        variant="danger"
                        @click="clearFilter"
                      >
                        <span>{{ $t('form.reset') }}</span>
                      </b-button>
                      <b-button
                        block
                        variant="outline-primary"
                        @click="$refs.dropdownFilter.hide(true)"
                      >
                        <span>{{ $t('Close') }}</span>
                      </b-button>
                    </b-dropdown-form>
                  </b-dropdown>
                </b-input-group-append>
              </b-input-group>
            </div>

            <div v-if="$route.params.userId">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="btn-icon"
                :to="{ path: `/users/${$route.params.userId}/campaigns/create`}"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                {{ $t('campaigns.campaignadd') }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="filtredColumns"
          :rows="filterRows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          style-class="vgt-table striped"
        >
          <template slot="column-filter">
            <span style="display:none;" />
          </template>
          <!-- Slot: Table Column -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <template slot="column-filter">
              <span style="display:none;" />
            </template>
            <span
              v-if="props.column.label ==='Status'"
              class="text-nowrap"
            >
              {{ $t('campaigns.status') }}
            </span>
            <span
              v-else-if="props.column.label ==='Name'"
              class="text-nowrap"
            >
              {{ $t('campaigns.campaigntitle') }}
            </span>
            <span
              v-else-if="props.column.label ==='Type'"
              class="text-nowrap"
            >
              {{ $t('campaigns.type') }}
            </span>
            <span
              v-else-if="props.column.label ==='Country'"
              class="text-nowrap"
            >
              {{ $t('stats.country') }}
            </span>
            <span
              v-else-if="props.column.label ==='Balance'"
              class="text-nowrap"
            >
              {{ $t('profile.balance') }}
            </span>
            <span
              v-else-if="props.column.label ==='Action'"
              class="text-nowrap"
            >
              {{ $t('Action') }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span
              v-if="props.column.field === 'status'"
              class="text-nowrap"
            >
              <b-form-checkbox
                :checked="props.row.status === 'active' ? true : false"
                name="check-button"
                switch
                size="lg"
                @change="changeStatus(props.row)"
              />
            </span>

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'title'"
              class="text-nowrap"
            >
              <router-link :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/'"><span>{{ trimEllip(props.row.title, 40) }}</span></router-link>
              <b-badge :variant="statusVariant(props.row.status)">
                {{ $t('campaigns.' + props.row.status) }}
              </b-badge>
              <b-badge
                v-if="props.row.is_moderate"
                class="ml-25"
                :variant="statusVariant('Resigned')"
              >
                {{ $t('campaigns.moderation') }}
              </b-badge>
              <b-badge
                v-if="props.row.startdate && new Date(props.row.enddate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)"
                class="ml-25"
                :variant="statusVariant('Resigned')"
              >
                {{ $t('campaigns.checkdate') }}
              </b-badge>
            </span>

            <!-- Column: Type -->
            <span
              v-if="props.column.field === 'type'"
              class="text-nowrap"
            >
              <small>{{ $t(`selects.adstype.${props.row.type}`) }}</small>
            </span>

            <!-- Column: Country -->
            <span
              v-if="props.column.field === 'country_name'"
              class="text-nowrap"
            >
              <span>
                <small>{{ props.row.country_name ? toCapitalize(props.row.country_name) : '' }}</small>
              </span>
            </span>

            <!-- Column: Balance -->
            <span
              v-if="props.column.field === 'balance'"
              class="text-nowrap"
            >
              <span>
                <small>{{ currency(props.row.currency, props.row.balance) }}</small>
              </span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span class="d-flex justify-content-center">
                <b-button
                  v-b-tooltip.hover.top="$t('Statistics')"
                  :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id"
                  variant="flat"
                  class="btn-action"
                >
                  <feather-icon
                    icon="BarChart2Icon"
                    size="16"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="$t('form.change')"
                  :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/edit/'"
                  variant="flat"
                  class="btn-action"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                  />
                </b-button>

                <template v-if="props.row.type !== 'criteo' && props.row.type !== 'cpcfeed' && props.row.type !== 'adsense'">
                  <b-button
                    v-b-tooltip.hover.top="$t('campaigns.banners')"
                    :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/creatives/table'"
                    variant="flat"
                    class="btn-action"
                  >
                    <feather-icon
                      icon="ImageIcon"
                      size="16"
                    />
                  </b-button>
                </template>

                <b-dropdown
                  variant="link"
                  right
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>

                  <b-dropdown-item
                    :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/clicks'"
                  >
                    <feather-icon
                      icon="MousePointerIcon"
                      size="16"
                      class="align-middle mr-50"
                    />
                    <span>{{ $t('Clicks sources') }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/pixelclicks'"
                  >
                    <feather-icon
                      icon="ActivityIcon"
                      size="16"
                      class="align-middle mr-50"
                    />
                    <span>{{ $t('page.features.pixelclick') }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/geoclicks'"
                  >
                    <feather-icon
                      icon="GlobeIcon"
                      size="16"
                      class="align-middle mr-50"
                    />
                    <span>{{ $t('page.features.geoclick') }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/payments'"
                  >
                    <feather-icon
                      icon="CreditCardIcon"
                      size="16"
                      class="align-middle mr-50"
                    />
                    <span>{{ $t('profile.balance') }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-clipboard:copy="getPixelCode(props.row._id)"
                    v-clipboard:success="onCopyPixelCode"
                    v-clipboard:error="onErrorCopyPixelCode"
                  >
                    <feather-icon
                      icon="CodeIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('campaigns.copypixel') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="authUserData.role.includes('admin')"
                    @click="copyCampaign(props.row)"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.copy') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item
                    v-if="authUserData.role.includes('admin')"
                    variant="danger"
                    @click="deleteCampaign(props.row._id, props.row.title)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','20','40']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  {{ totals = props.total }}
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
      <div v-else>
        <div
          class="text-center"
        >
          <h5>{{ $t('campaigns.nocampaigns') }}</h5>
          <p>{{ $t('campaigns.addcampaign') }}:</p>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            :to="{ path: `/users/${$route.params.userId}/campaigns/create`}"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            {{ $t('campaigns.campaignadd') }}
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import currencyFormatter from '@/utils/currency-formatter'
import {
  BCard, BSpinner, BBadge, BPagination, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormCheckbox, BFormSelect, BDropdown, BDropdownItem, BDropdownForm, BDropdownDivider, BButton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { getUserData } from '@/auth/utils'
import { trimEllip, toCapitalize } from '@/utils/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Breadcrumbs,
    UserToolbar,
    BSpinner,
    BCard,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BDropdownDivider,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      authUserData: getUserData(),
      account: {},
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      loading: true,
      currency: currencyFormatter,
      pageLength: 20,
      dir: false,
      toCapitalize,
      trimEllip,
      columns: [
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
          width: '9%',
          type: 'boolen',
        },
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            filterValue: '',
          },
          hidden: false,
          width: '15%',
        },
        {
          label: 'Country',
          field: 'country_name',
          filterOptions: {
            enabled: true,
            filterValue: '',
          },
          width: '15%',
        },
        {
          label: 'Balance',
          field: 'balance',
          type: 'number',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '5%',
          sortable: false,
        },
      ],
      rows: [],
      dispaly_rows: [],
      searchTerm: '',
      filterStatus: '',
      totals: 0,
      optionsStatuses: [
        { value: 'is_active', text: this.$t('selects.filter.active') },
        { value: 'is_moderate', text: this.$t('selects.filter.moderation') },
        { value: 'is_new', text: this.$t('selects.filter.new') },
        { value: 'is_outdate', text: this.$t('selects.filter.outdate') },
      ],
      ...options,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        active       : 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    dropdownFilterVariant() {
      if (this.filterStatus !== '' || this.getFilterValue('country_name') !== '' || this.getFilterValue('type') !== '') {
        return 'link'
      }
      return 'link-secondary'
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    filtredColumns() {
      if (this.authUserData.is_agency_member) {
        return this.columns.filter(f => f.field !== 'balance')
      }
      return this.columns
    },
    filterRows() {
      let filtred = []
      if (this.filterStatus !== '') {
        if (this.filterStatus === 'is_new') {
          filtred = this.rows.filter(r => (new Date() - new Date(r.created_at)) <= (3 * 24 * 60 * 60 * 1000))
        } else if (this.filterStatus === 'is_active') {
          filtred = this.rows.filter(r => r.status === 'active')
        } else if (this.filterStatus === 'is_moderate') {
          filtred = this.rows.filter(r => r.is_moderate)
        } else if (this.filterStatus === 'is_outdate') {
          const currentDate = new Date()
          const dateWidthDelta = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1)
          filtred = this.rows.filter(r => (r.enddate && r.status === 'active') && (new Date(r.enddate) < dateWidthDelta))
        }
        return filtred
      }
      return this.rows
    },
  },
  async created() {
    if (this.$route.params.userId !== undefined) {
      this.getUserData()
    } else {
      this.pageTitle = this.$t('Campaigns')
      this.fetchCampaigns()
    }
  },
  methods: {
    clearFilter() {
      this.filterStatus = ''
      this.filterFn('country_name', '')
      this.filterFn('type', '')
    },
    clearSearch() {
      this.searchTerm = ''
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || []
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.fetchCampaigns()
    },
    async fetchCampaigns() {
      const responseData = this.$route.params.userId !== undefined ? await useJwt.accountCampaigns(this.$route.params.userId) : await useJwt.campaigns()
      this.rows = Object.freeze(responseData.data.campaigns) || []
      this.loading = false
      // this.dispaly_rows.forEach((c, i) => setTimeout(() => this.dispaly_rows.push(c), i * 200))
      // this.rows = await store.dispatch('dashboard-campaings/fetchCampaigns')
      this.setBreabcrumbs()
    },
    checkEnter(event) {
      if (event.code !== 'Enter') {
        event.preventDefault()
      }
    },
    setBreabcrumbs() {
      if (this.$route.params.userId !== undefined) {
        this.breadcrumbItems = [
          {
            text: 'users',
            to: '/users',
          },
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: 'campaigns',
            active: true,
          },
        ]
      } else {
        this.breadcrumbItems = [
          {
            text: 'campaigns',
            active: true,
          },
        ]
      }
    },
    async changeStatus(campaign) {
      campaign.status = campaign.status === 'inactive' ? 'active' : 'inactive'
      campaign.startdate = campaign.startdate !== null && campaign.startdate !== '' ? new Date(campaign.startdate).toISOString() : campaign.startdate
      campaign.enddate = campaign.enddate !== null && campaign.enddate !== '' ? new Date(campaign.enddate).toISOString() : campaign.enddate

      if (campaign.targetings.date.params !== undefined) {
        campaign.targetings.date.params.startdate = new Date(campaign.targetings.date.params.startdate).toISOString()
        campaign.targetings.date.params.enddate = new Date(campaign.targetings.date.params.enddate).toISOString()
      }

      if (campaign.balance <= 0) {
        this.$swal({
          title: 'Please top up balance',
          text: 'Not enough balance to activate',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (campaign.status === 'active') {
        useJwt.setCampaign(campaign._id, { campaign })
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Activation!',
              text: 'Successfully activated.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(error => {
            campaign.status = 'inactive'
            if (error.response && error.response.status === 422) {
              if (error.response.data.errors.length) {
                error.response.data.errors.forEach(err => {
                  const field = err.source.pointer.split('/')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: `${field[field.length - 1]}: ${err.detail}`,
                    },
                  })
                })
              }
            }
          })
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            campaign.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setCampaign(campaign._id, { campaign })
        })
      }
    },
    async copyCampaign(campaign) {
      const dataResponse = await useJwt.getCampaignBanners({ campaign_id: campaign._id })
      const banners = dataResponse.data.banners || []

      delete campaign._id
      const title = `${campaign.title} (Copy)`
      const newCampaign = {
        ...campaign,
        startdate: null,
        enddate: null,
        dailyclicks: 0,
        title,
      }
      await useJwt.createCampaign({ campaign: newCampaign })
        .then(async response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('campaigns.copysuccess'),
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          if (this.$route.params.userId !== undefined) {
            this.getUserData()
          } else {
            this.pageTitle = this.$t('Campaigns')
            this.fetchCampaigns()
          }

          const newCampaignId = response.data.campaign._id
          this.copyBanners(banners, newCampaignId)
        })
    },
    async copyBanners(banners, newCampaignId) {
      if (banners.length) {
        banners
          .map(b => ({ ...b, campaign_id: newCampaignId }))
          .forEach(async newBanner => {
            await useJwt.uploadBanner(newBanner)
          })
      }
    },
    deleteCampaign(id, name) {
      this.$swal({
        title: `${this.$t('swal.deletetitle')} ${name}?`,
        text: this.$t('swal.campaigndeletemessage'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('form.cancel'),
        confirmButtonText: this.$t('form.delete'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteCampaign(id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.fetchCampaigns()
            }
          })
        }
      })
    },
    filterFn(field, value) {
      const found = this.columns.find(c => c.field === field)
      if (found && found.filterOptions) {
        Object.assign(found.filterOptions, { filterValue: value })
      }
    },
    getFilterValue(field) {
      const found = this.columns.find(c => c.field === field)
      if (found && found.filterOptions && 'filterValue' in found.filterOptions && found.filterOptions.filterValue !== '') {
        return found.filterOptions.filterValue
      }
      return ''
    },
    getPixelCode(campaignId) {
      return `<script>!function(e,t,a){var n=t.getElementsByTagName("script")[0],c=t.createElement("script"),i=function(){n.parentNode.insertBefore(c,n)};c.dataset.id="${campaignId}",c.type="text/javascript",c.async=!0,c.src="//phoenix-widget.com/static/js/pixel.js","[object Opera]"==e.opera?t.addEventListener("DOMContentLoaded",i,!1):i()}(window,document);<${''}/script>`
    },
    onCopyPixelCode() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('campaigns.copypixelcopy'),
          icon: 'BellIcon',
        },
      })
    },
    onErrorCopyPixelCode() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('campaigns.copypixelerror'),
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
<style scoped>
@media (min-width: 768px) {
  .vgt-responsive {
    overflow-x: visible !important;
  }
}
</style>
